@use 'sass:map';
@import '../tools/transitions';

.multiselect {
	position: relative;
}

.multiselect-input {
	@include transition(border-color);

	position: relative;
	padding-right: spacing(6);
	cursor: pointer;

	&::before {
		@include transition(transform);

		content: '';
		position: absolute;
		top: 50%;
		right: spacing(2);
		width: px2rem(12px);
		height: px2rem(6px);
		background-image: url('../../images/svg/arrow.svg');
		background-size: contain;
		background-repeat: no-repeat;
		transform: translateY(-50%);
	}
}

.multiselect-input.selected {
	&::before {
		display: none;
	}
}

.multiselect-erase {
	position: absolute;
	top: 50%;
	right: spacing(1);
	width: px2rem(24px);
	height: px2rem(24px);
	background-image: url('../../images/svg/close.svg');
	background-size: contain;
	background-repeat: no-repeat;
	visibility: hidden;
	transform: translateY(-50%);
	cursor: pointer;
}

.multiselect-input.selected > .multiselect-erase {
	visibility: visible;
}

.multiselect.opened > .multiselect-input {
	border-color: $green-300;

	&::before {
		transform: rotate(180deg) translateY(50%);
	}
}

.multiselect-dropdown {
	position: absolute;
	z-index: $zindex-dropdown;
	display: none;
	width: 100%;
	min-width: px2rem(220px);
	border: $border-width solid $gray-200;
	border-radius: px2rem(4px);
	background-color: $color-white;
	box-shadow: $shadow;
}

.multiselect.opened > .multiselect-dropdown {
	display: block;
}

.multiselect-inner {
	max-height: px2rem(220px);
	overflow-y: auto;
}

.multiselect-inner::-webkit-scrollbar-track {
	background-color: $color-white;
}

.multiselect-inner::-webkit-scrollbar {
	width: px2rem(4px);
	background-color: $color-white;
}

.multiselect-inner::-webkit-scrollbar-thumb {
	border-radius: px2rem(2px);
	background-color: $gray-200;
}

.multiselect-option,
.multiselect-title {
	position: relative;
	cursor: pointer;

	&::before {
		content: '';
		position: absolute;
		top: 50%;
		left: spacing(3);
		width: px2rem(18px);
		height: px2rem(18px);
		border: $border-width solid $gray-900;
		border-radius: px2rem(3.5px);
		transform: translateY(-50%);
	}
}

.multiselect-title {
	padding: spacing(3) spacing(3) spacing(3) spacing(8);
	font-weight: map.get($typography-font-weight-values, medium);

	&:hover {
		background-color: $aqua-100;
	}
}

.multiselect-option {
	padding: spacing(3) spacing(3) spacing(3) spacing(8);

	&::before {
		left: spacing(3);
	}

	&:hover {
		background-color: $aqua-100;
	}
}

.multiselect-group > .multiselect-option {
	padding: spacing(3) spacing(3) spacing(3) spacing(11);

	&::before {
		left: spacing(5);
	}
}

.multiselect-option.selected::before,
.multiselect-title.selected::before {
	border-color: $green-300;
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' width='10' height='5' viewBox='0 0 10 9'%3e %3cpath stroke='%23fff' stroke-linecap='round' stroke-width='1.5' d='M1 4.3 3.5 7 9 1'/%3e%3c/svg%3e");
	background-position: center;
	background-size: contain;
	background-repeat: no-repeat;
	background-color: $green-300;
}
