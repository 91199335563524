.btn-primary {
	--bs-btn-color: #{$color-white};
	--bs-btn-active-color: #{$color-white};
	--bs-btn-hover-color: #{$color-white};
	--bs-btn-hover-bg: #{$green-400};
	--bs-btn-hover-border-color: #{$green-400};
}

.btn-danger {
	--bs-btn-color: #{$color-white};
	--bs-btn-active-color: #{$color-white};
	--bs-btn-hover-color: #{$color-white};
	--bs-btn-bg: #{$red-100};
	--bs-btn-border-color: #{$red-100};
	--bs-btn-hover-bg: #{$red-200};
	--bs-btn-hover-border-color: #{$red-200};
}

.btn-outline-secondary {
	--bs-btn-color: #{$gray-900};
	--bs-btn-border-color: #{$gray-300};
	--bs-btn-hover-bg: #{$gray-200};
	--bs-btn-hover-border-color: #{$gray-200};
}

.btn-icon {
	padding: px2rem(7px);
}

.btn-icon > svg,
.btn-icon > span > svg {
	width: px2rem(20px);
	height: auto;
}
