@use 'sass:map';
@import '../tools/px2rem';

$border-width-values: (
	default: px2rem(1px),
	large: px2rem(3px),
);
$border-width: map.get($border-width-values, default);

$border-radius-values: (
	default: px2rem(4px),
	mid: px2rem(6px),
	large: px2rem(16px),
	larger: px2rem(30px),
);
$border-radius: map.get($border-radius-values, default);
