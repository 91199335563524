@use 'sass:map';
@import '../tools/transitions';

.alert {
	--bs-alert-padding-x: 0;
	--bs-alert-padding-y: 0;
	--bs-alert-margin-bottom: 0;

	@include transition(opacity);

	position: fixed;
	top: spacing(20);
	right: 0;
	z-index: map.get($z-index-values, alert);
	display: inline-flex;
	align-items: center;
	min-width: px2rem(270px);
	max-width: px2rem(360px);
	height: spacing(9);
	opacity: 0;

	@include breakpoint-up(sm) {
		max-width: px2rem(500px);
	}
}

.alert-icon {
	width: spacing(9);
	height: spacing(9);
	border: 0;
	background-color: transparent;
}

.alert-content {
	display: inline-flex;
	padding: px2rem(12px);
}

.alert-text {
	min-width: px2rem(190px);
	border: 0;
}

.alert-success {
	--bs-alert-color: #{$color-black};
	--bs-alert-bg: #{$color-white};
	--bs-alert-border-radius: #{map.get($border-radius-values, default)};

	border: 0;
}

.alert-btn-close {
	margin-left: spacing(2);
	border: 0;
	background: transparent;
}

.alert-visible {
	opacity: 1;
}
