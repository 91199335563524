@use 'sass:map';

tbody > tr {
	cursor: pointer;
}

th {
	position: relative;
}

.table {
	--bs-table-hover-bg: $color-white;
}

.table > thead > tr {
	border-top: $border-width solid $gray-200;
	border-bottom: $border-width solid $blue-300;
}

.table > tbody > tr:hover {
	box-shadow: $shadow-narrow;
	transform: scale(1);
}

/* stylelint-disable selector-max-compound-selectors */
.table > thead > tr > .hidden,
.table > tbody > tr > .hidden {
	display: none;
}

.table > thead > tr > .hidden.visible-xxxl,
.table > tbody > tr > .hidden.visible-xxxl {
	@include breakpoint-up(xxxl) {
		display: table-cell;
	}
}

.table > thead > tr > .hidden.visible-xxxxl,
.table > tbody > tr > .hidden.visible-xxxxl {
	@include breakpoint-up(xxxxl) {
		display: table-cell;
	}
}
/* stylelint-enable */

.table-search-bar {
	position: relative;
	z-index: map.get($z-index-values, table);
	background-color: $color-white;
}

.table-icon {
	display: none;
	margin-right: spacing(1);
}

.table-icon:last-child {
	margin-right: spacing(2);
}

tr:hover .table-icon {
	display: inline-block;
}

.table-buttons {
	width: spacing(22);
	text-align: right;
	white-space: nowrap;
}

.table-field-priority {
	position: relative;
}

.table-field-priority::before {
	content: '';
	position: absolute;
	top: spacing(4);
	left: spacing(-1);
	width: px2rem(8px);
	height: px2rem(8px);
	border: map.get($border-width-values, default) solid $red-100;
	border-radius: 50%;
	background-color: $red-100;
}
