.table-sort {
	position: absolute;
	left: auto;
	margin-left: px2rem(7px);
}

.table-sort-up {
	top: px2rem(10px);
}

.table-sort-down {
	top: px2rem(18px);
}

.table-sort-link {
	color: $gray-900;
}

.table-sort-link-active {
	color: $green-400;
}
