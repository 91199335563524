$color-black: #000;
$color-white: #fff;

$aqua-100: #e8f5f5;
$aqua-200: #c3e4e2;

$gray-100: #f5f5f5;
$gray-200: #ebebeb;
$gray-300: #d7d7d7;
$gray-400: #c3c3c3;
$gray-500: #afafaf;
$gray-600: #9b9b9b;
$gray-700: #737373;
$gray-800: #4b4b4b;
$gray-900: #2d2d2d;

$green-100: #a7bcb0;
$green-200: #59c986;
$green-300: #00afaa;
$green-400: #048985;
$green-500: #004c45;

$blue-100: #d6d8df;
$blue-200: #4f86a0;
$blue-300: #022a3a;
$blue-400: #06161d;

$yellow-100: #f8aa13;

$red-100: #ea5248;
$red-200: #bc271d;

$primary: $green-300;
$success: $green-200;
$info: $blue-200;
$link-color: $blue-200;

$body-bg: #f9f9f9;

$light: $body-bg;

$danger: #ea5248;
