@use 'sass:map';
@import 'typography';

$font-size-base: map.get($display-font-sizes, 0);
$font-family-sans-serif: $typography-font-family-primary;

@font-face {
	font-style: normal;
	font-weight: 400;
	font-family: 'Helvetica Neue';
	font-display: swap;
	src:
		url('../../fonts/HelveticaNeue.woff') format('woff'),
		url('../../fonts/HelveticaNeue.woff2') format('woff2');
}

@font-face {
	font-style: normal;
	font-weight: 500;
	font-family: 'Helvetica Neue';
	font-display: swap;
	src:
		url('../../fonts/HelveticaNeue-Medium.woff') format('woff'),
		url('../../fonts/HelveticaNeue-Medium.woff2') format('woff2');
}

@font-face {
	font-style: normal;
	font-weight: 700;
	font-family: 'Helvetica Neue';
	font-display: swap;
	src:
		url('../../fonts/HelveticaNeue-Bold.woff') format('woff'),
		url('../../fonts/HelveticaNeue-Bold.woff2') format('woff2');
}
