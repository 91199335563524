@use 'sass:map';

.btn-close {
	position: absolute;
	top: spacing(2);
	right: spacing(2);
	border: $border-width solid $gray-300;
	border-radius: map.get($border-radius-values, large);
	background: transparent url('../../images/svg/close.svg') center no-repeat;
}
