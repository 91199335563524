@use 'sass:map';

.clincheck {
	@include transition(transform, map.get($transition-durations, slow));

	position: fixed;
	top: $header-height;
	left: 50%;
	z-index: $zindex-sticky;
	width: 50%;
	height: calc(100% - #{$header-height});
	overflow: auto;
	background-color: $color-white;
	transform: translateX(100%);

	@include breakpoint-up(xxxl) {
		left: 60%;
		width: 40%;
	}

	@include breakpoint-up(xxxxl) {
		left: 67%;
		width: 33%;
	}
}

.clincheck.opened {
	transform: translateX(0%);
}

.clincheck-inner {
	max-width: px2rem(640px);
	padding: spacing(4);
}

.clincheck-close {
	display: flex;
	align-items: center;
	justify-content: center;
	width: px2rem(37px);
	height: px2rem(37px);
}

.clincheck-close > svg {
	width: px2rem(12px);
	height: auto;
}

.clincheck-wrap.opened {
	width: 50%;

	@include breakpoint-up(xxxl) {
		width: 60%;
	}

	@include breakpoint-up(xxxxl) {
		width: 75%;
	}
}
