@use 'sass:map';

.modal {
	--bs-modal-margin: #{0.5rem};
	--bs-modal-width: 640px;
	--bs-modal-border-width: 0;

	@include breakpoint-up(md) {
		--bs-modal-margin: #{10rem};
	}
}

.modal-header {
	justify-content: center;
	border-bottom: 0;
}

.modal-header > .btn-close {
	padding: spacing(2);
	margin: 0;
}

.modal-title {
	text-align: center;
}

.modal-content {
	border-radius: map.get($border-radius-values, large);
}

.modal-body {
	padding-inline: spacing(5);

	@include breakpoint-up(md) {
		padding-inline: spacing(30);
	}
}

.modal-footer {
	justify-content: space-between;
	padding: spacing(4) spacing(6);
	border-top: 0;
}
