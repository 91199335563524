@use 'sass:map';

.badge {
	--bs-badge-padding-x: #{spacing(1)};
	--bs-badge-padding-y: #{spacing(1)};
	--bs-badge-font-weight: #{map.get($typography-font-weight-values, medium)};
	--bs-badge-font-size: #{map.get($display-font-sizes, -1)};

	line-height: #{map.get($typography-line-height-values, 0)};
}
