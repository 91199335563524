@use 'sass:map';
@import '../tools/transitions';

.input-group-text.input-group-text {
	@include transition(border-color);

	border-right: 0;
}

.input-group:focus-within > .input-group-text {
	border-color: $green-300;
}

.input-group-text-search {
	border: $border-width solid $gray-100;
	border-top-left-radius: map.get($border-radius-values, larger);
	border-bottom-left-radius: map.get($border-radius-values, larger);
	background-color: $gray-100;
}

.input-group-text-search > svg {
	color: $gray-700;
}

.form-control-search {
	padding-right: px2rem(30px);
	text-overflow: ellipsis;
	border: $border-width solid $gray-100;
	border-top-right-radius: map.get($border-radius-values, larger);
	border-bottom-right-radius: map.get($border-radius-values, larger);
	background-color: $gray-100;
}

.form-control-search:focus {
	background-color: $gray-100;
}

.form-check {
	margin-bottom: spacing(2);
}

.form-check-input {
	width: px2rem(18px);
	height: px2rem(18px);
	margin-top: px2rem(-1px);
	vertical-align: middle;
	border: $border-width solid $gray-900;
}

.form-check .form-check-label {
	margin-left: px2rem(7px);
}

.form-check-input:checked[type='radio'] {
	background-position: center;
	background-size: px2rem(10px);
}

.input-group > .input-date::-webkit-calendar-picker-indicator {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 100%;
	color: transparent;
	background: transparent;
	cursor: pointer;
}

.input-group-label {
	position: absolute;
	top: spacing(1);
	left: spacing(8);
	z-index: 990;
	display: none;
	font-weight: map.get($typography-font-weight-values, medium);
	font-size: map.get($display-font-sizes, -2);
	color: $gray-600;
}

.input-group.active > .input-group-label {
	display: block;
}

.input-group.active > .form-control {
	padding-block: spacing(3) spacing(1);
}
