@use 'sass:map';

.fw-medium {
	font-weight: map.get($typography-font-weight-values, medium);
}

.text-truncate-multi {
	/* stylelint-disable-next-line value-no-vendor-prefix */
	display: -webkit-box;
	overflow: hidden;
	text-overflow: ellipsis;
	overflow-wrap: break-word;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
}
