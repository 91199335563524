.mw-200 {
	max-width: px2rem(200px);
}

.mw-140 {
	max-width: px2rem(140px);
}

.opened > .mw-200,
.opened > .mw-140 {
	max-width: initial;
}
