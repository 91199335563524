@use 'sass:map';

$form-label-margin-bottom: spacing(1);
$form-label-font-size: map.get($display-font-sizes, -1);
$form-label-font-weight: map.get($typography-font-weight-values, medium);

$input-padding-y: spacing(2);
$input-padding-x: spacing(2);
$input-line-height: map.get($typography-line-height-values, 1);
$input-bg: $color-white;
$input-border-color: $gray-300;
$input-focus-border-color: $green-300;
$input-focus-box-shadow: none;

$form-check-input-checked-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' fill='none' width='10' height='5' viewBox='0 0 10 9'> <path stroke='#fff' stroke-linecap='round' stroke-width='1.5' d='M1 4.3 3.5 7 9 1'/></svg>");
$form-check-radio-checked-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' fill='none' width='6' height='4' viewBox='0 0 10 8'><path stroke='#fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='m.75 4 2.83 2.83 5.67-5.66'/></svg>");

$input-group-addon-padding-y: px2rem(10px) 0 px2rem(10px);
$input-group-addon-bg: $color-white;

$input-disabled-bg: $color-white;
