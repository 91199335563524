@use 'sass:map';

.progress {
	--bs-progress-height: 3px;
	--bs-progress-bg: #{$gray-300};
}

.progress-bar {
	animation-name: progress-bar;
	animation-duration: 4500ms;
	animation-iteration-count: 1;
}

@keyframes progress-bar {
	0% {
		width: 0%;
	}

	100% {
		width: 100%;
	}
}
