@use 'sass:map';
@import '../tools/px2rem';
@import '../tools/spacing';
@import 'colors';

$typography-font-weight-values: (
	regular: 400,
	medium: 500,
	bold: 700,
);

$typography-line-height-values: (
	-2: 0.8,
	-1: 1,
	0: 1.2,
	1: 1.4,
	2: 1.5,
);

$line-height-base: map.get($typography-line-height-values, 0);

$typography-font-size-base: 93.75%;
$typography-font-weight-base: map.get($typography-font-weight-values, regular);
$typography-font-family-primary: 'Helvetica Neue', sans-serif;
$typography-line-height-base: map.get($typography-line-height-values, 0);

$display-font-sizes: (
	-2: px2rem(10px),
	-1: px2rem(12px),
	0: px2rem(14px),
	1: px2rem(15px),
	2: px2rem(16px),
	3: px2rem(20px),
	4: px2rem(32px),
);

$display-font-weight: map.get($typography-font-weight-values, regular);
