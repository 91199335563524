@use 'sass:map';

.nav {
	--bs-nav-tabs-link-active-color: #{$green-300};
	--bs-nav-tabs-link-active-border-color: #{$green-300};
	--bs-nav-tabs-link-active-bg: #{$color-white};
	--bs-nav-pills-link-active-bg: #{$color-white};
	--bs-nav-pills-border-radius: #{0};
	--bs-nav-link-color: #{$green-300};
	--bs-nav-link-hover-color: #{$green-300};
}

.nav-primary > .nav-item > .nav-link {
	display: flex;
	align-items: center;
	font-size: map.get($display-font-sizes, 2);
	color: $blue-300;
	border: solid transparent;
	border-width: 0 0 map.get($border-width-values, large) 0;

	@include breakpoint-up(sm) {
		padding: spacing(5) spacing(1);
		margin-right: spacing(8);
	}
}

.nav-link > svg {
	margin-right: spacing(1);
}

.nav-pills > .nav-item > .nav-link {
	padding: spacing(2) spacing(5);
	color: $gray-900;
	border: solid transparent;
	border-width: 0 0 map.get($border-width-values, large) 0;
}

.nav-pills > .nav-item {
	margin-right: spacing(1);
	border-bottom: $border-width solid $gray-200;
	border-top-left-radius: map.get($border-radius-values, mid);
	border-top-right-radius: map.get($border-radius-values, mid);
	background-color: $color-white;
	box-shadow: $shadow;
}

.nav > .nav-item > .nav-link:hover,
.nav > .nav-item > .nav-link.active {
	color: $green-300;
	border-width: 0 0 map.get($border-width-values, large) 0;
	border-color: $green-300;
	border-top-left-radius: map.get($border-radius-values, mid);
	border-top-right-radius: map.get($border-radius-values, mid);
}

.nav-pills {
	border-bottom: 0;
}
