@use 'sass:map';

.loader > img {
	width: px2rem(22px);
	height: auto;
}

.loader-backdrop {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: $zindex-sticky;
	background-color: rgba($color-white, 0.8);
}

.loader-page {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.loader-page > img {
	width: px2rem(120px);
	height: auto;
}
