.input-group > input {
	border-left: 0;
}

.input-group-text {
	border-right: 0;
}

.input-group-text-search {
	border-top-left-radius: px2rem(30px);
	border-bottom-left-radius: px2rem(30px);
	background-color: $gray-100;
}

.input-group-text-search > svg {
	color: $gray-700;
}

.form-control-search {
	border-top-right-radius: px2rem(30px);
	border-bottom-right-radius: px2rem(30px);
	background-color: $gray-100;
}

.form-control-search:focus {
	background-color: $gray-100;
}

.form-control:disabled {
	cursor: not-allowed;
}

.form-control-search-erase {
	position: absolute;
	top: 50%;
	right: spacing(1);
	z-index: $zindex-dropdown;
	width: px2rem(24px);
	height: px2rem(24px);
	background-image: url('../../images/svg/close.svg');
	background-size: contain;
	background-repeat: no-repeat;
	visibility: hidden;
	transform: translateY(-50%);
	cursor: pointer;
}

.input-group.has-value > .form-control-search-erase {
	visibility: visible;
}

.input-group.has-value > .form-control-search,
.input-group.has-value > .input-group-text {
	border-color: $green-300;
}
