@use 'sass:map';

.rating {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	padding: spacing(2) spacing(5) spacing(3);
	background-color: $gray-100;
}

.rating-title {
	width: 100%;
	font-weight: map.get($typography-font-weight-values, medium);
	text-align: center;
}

.rating-item {
	@include transition(border-color);

	width: px2rem(64px);
	height: px2rem(64px);
	padding: px2rem(9px);
	border: $border-width solid $gray-200;
	border-radius: 50%;
	background-color: $color-white;
}

.rating-item-bad:hover {
	border-color: $red-100;
}

.rating-item-neutral:hover {
	border-color: $yellow-100;
}

.rating-item-happy:hover {
	border-color: $green-300;
}

.rating-item-bad.active {
	color: $red-100;
}

.rating-item-neutral.active {
	color: $yellow-100;
}

.rating-item-happy.active {
	color: $green-300;
}
