@use 'sass:map';
@import '../tools/transitions';

.note {
	@include transition(border-color);

	position: relative;
	min-height: px2rem(60px);
	padding: spacing(2);
	margin-bottom: spacing(1);
	border: $border-width solid transparent;
	border-radius: $border-radius;
	background-color: $gray-200;
}

.note:hover {
	border-color: $gray-300;
}

.note-text {
	padding-right: spacing(10);
	margin-bottom: 0;
}

.note-btn {
	@include transition(opacity);

	position: absolute;
	top: spacing(2);
	right: spacing(2);
	opacity: 0;
}

.note-btn > svg {
	width: px2rem(20px);
	height: auto;
	margin-block: px2rem(4px);
}

.note:hover > .note-btn {
	opacity: 1;
}
