html {
	overflow-x: hidden;
	font-size: $typography-font-size-base;
}

body {
	padding-top: px2rem(90px);
	-webkit-tap-highlight-color: transparent;
}

h1 {
	margin-bottom: spacing(4);
	color: $blue-300;
}
